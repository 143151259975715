import React from "react"
import GoogleMapReact from "google-map-react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { map, fill, subtitle, description } from "./contact.module.css"

const contact = () => {
  const defaultProps = {
    center: {
      lat: 51.1321458867669,
      lng: 5.454617352838536,
    },
    zoom: 13,
  }

  return (
    <Layout>
      <Seo title="Say hello" />
      <section className={`container ${fill}`}>
        <h2 className="title">say hello.</h2>
        <br />
        <p className={description}>
          <span>
            I am always eager to meet like-minded people to discuss ideas,
            campaigns and/or books. Please do not hesitate to make a connection
            on LinkedIn or send an email to jente-stevens@hotmail.com.
          </span>
          <br />
          <br />
          <span>
            Just tell me how you like your coffee, so we can meet in person as
            soon as possible.
          </span>
        </p>
        <h3 className={subtitle}>My current location.</h3>
        <div className={map}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.GATSBY_API_KEY,
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          ></GoogleMapReact>
        </div>
      </section>
    </Layout>
  )
}

export default contact
